import React, { useEffect, useRef, useState } from 'react';
import { Container, createStyles, Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import { useLayout } from '../Layout/LayoutContext';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { catalogService } from '../Utils/ApiService';
import EText from '../Components/EText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import InStockBanner from '../Components/InStockBanner';
import ProductPricePanel from '../Components/ProductPricePanel';
import DescriptionPanel from '../Components/DescriptionPanel';
import { scrollToRefObject } from '../Utils/Common';
import MagistrRecommendsCard from '../Components/AlternativeMagistrRecommendsCard';
import DeliveryLeafletOrderPanel from '../Components/DeliveryLeafletOrderPanel';
import ProductsPreviewPanel from '../Components/ProductsPreviewPanel';
import QualityLabels from '../Components/QualityLabels';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import HeadMap from '../Components/HeadMap';
import LabelsContainer from '../Components/LabelsContainer';
import TopSoldProdCard from '../Components/TopSoldProdCard';
import { MiniBanner } from '../Components/MiniBanner';
import { getPrice, getPriceType, setCorrectVerifyLogos } from '../Utils/Product';
import Substancesbutton from '../Components/SubstancesButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: '100%',
    },
    video: {
      border: 'none',
      width: '100%',
      height: '100%',
    },
    multilineText: {
      whiteSpace: 'pre-line',
      textAlign: 'justify',
    },
    pointer: {
      cursor: 'pointer',
    },
    imageContainer: {
      position: 'relative',
      paddingRight: 32,
    },
    labelContainer: {
      position: 'absolute',
      fontFamily: 'Roboto',
      color: 'white',
      padding: 4,
      margin: 0,
      textAlign: 'center',
      '&:hover': {
        opacity: '90%',
      },
      cursor: 'pointer',
    },
    lightBox: {
      top: 0,
      left: 0,
      overflow: 'hidden',
      height: '100%',
      width: '100%',
      position: 'fixed',
      zIndex: 999,
    },
    substancesButton: {
      margin: '20px 0',
    },
  })
);

interface IProps {
  productId: number;
}

export default function ProductDetails({ productId }: IProps) {
  const [productState, setProductState] = useState<{
    product: IProduct | undefined;
    alternatives: IProduct[];
  }>({
    product: undefined,
    alternatives: [],
  });
  const [reccomendedAlternative, setReccomendedAlternative] = useState<IProduct | undefined>(undefined);
  const [similarProducts, setSimilarProducts] = useState<IProduct[]>([]);
  const [combineWith, setCombineWith] = useState<IProduct[]>([]);
  const [alternativeForms, setAlternativeForms] = useState<IProduct[]>([]);
  const [alternativeTastes, setAlternativeTastes] = useState<IProduct[]>([]);
  const [selectedAmmount, setSelectedAmmount] = useState(1);
  const [lightBoxOpened, setLightBoxOpened] = useState(false);

  const layout = useLayout();
  const classes = useStyles();
  const theme = useTheme();
  const detailsRef = useRef(null);
  const similarProductsRef = useRef(null);

  const { product, alternatives } = productState;
  const price = product ? getPrice(product, layout.homeConfig.todaysSpecial) : 0;
  const priceType = getPriceType();
  const [lowestPriceIn30Days, setLowestPriceIn30Days] = useState<number>(0);
  const unitPrice = price / (product?.ammount || 1);

  const loadAlternatives = (prd: IProduct) => {
    if (prd.alternatives && prd.alternatives.length > 0) {
      catalogService.get(
        '/products/noauth/list',
        { ids: prd.alternatives, mapped: true, publishedOnly: true },
        {
          success: (alts: IProduct[]) => {
            setProductState({ product: prd, alternatives: alts });
          },
          error: layout.error,
        }
      );
    } else {
      setProductState({ product: prd, alternatives: [] });
    }
  };

  const loadSimilarProducts = (prd: IProduct) => {
    if (prd.activeSubstances && prd.activeSubstances.length > 0) {
      catalogService.get(
        'products/noauth/filter',
        {
          filterType: 'activeSubstances',
          filterValue: prd.activeSubstances?.join(';'),
        },
        {
          success: setSimilarProducts,
          error: () => console.error('Při načítání podobných produktů došlo k chybě.'),
        }
      );
    } else {
      setSimilarProducts([]);
    }
  };

  const loadCombineWith = (prd: IProduct) => {
    if (prd.combineWith && prd.combineWith.length > 0) {
      catalogService.get(
        'products/noauth/list',
        { ids: prd.combineWith, mapped: true, publishedOnly: true },
        {
          success: setCombineWith,
          error: () => console.error('Při načítání "Kombinujte s" došlo k chybě.'),
        }
      );
    } else {
      setCombineWith([]);
    }
  };

  const loadAlternativeForms = (prd: IProduct) => {
    if (prd.alternativeForms && prd.alternativeForms.length > 0) {
      catalogService.get(
        'products/noauth/list',
        { ids: prd.alternativeForms, mapped: true, publishedOnly: true },
        {
          success: setAlternativeForms,
          error: () => console.error('Při načítání alternatiních forem došlo k chybě.'),
        }
      );
    } else {
      setAlternativeForms([]);
    }
  };

  const loadAlternativeTastes = (prd: IProduct) => {
    if (prd.alternativeTastes && prd.alternativeTastes.length > 0) {
      catalogService.get(
        'products/noauth/list',
        { ids: prd.alternativeTastes, mapped: true, publishedOnly: true },
        {
          success: setAlternativeTastes,
          error: () => console.error('Při načítání alternatiních příchutí došlo k chybě.'),
        }
      );
    } else {
      setAlternativeTastes([]);
    }
  };

  const loadReccomendedAlternative = (prd: IProduct) => {
    if (prd.reccomendedAlternative && prd.reccomendedAlternative.alternativeId) {
      catalogService.get(
        '/products/noauth/',
        { id: prd.reccomendedAlternative.alternativeId, mapped: true },
        {
          success: (alt) => {
            if (alt.published) setReccomendedAlternative(alt);
          },
          error: layout.error,
        }
      );
    } else {
      setReccomendedAlternative(undefined);
    }
  };

  const loadProduct = (prdId: number) => {
    catalogService.get(
      'products/noauth',
      { id: prdId, mapped: true },
      {
        success: (data: IProduct) => {
          setCorrectVerifyLogos(layout.setSuklVerifyLogoVisible, layout.setVeterinaryLogoVisible, [data]);

          loadAlternatives(data);
          loadReccomendedAlternative(data);
          loadSimilarProducts(data);
          loadCombineWith(data);
          loadAlternativeForms(data);
          loadAlternativeTastes(data);
        },
        error: layout.error,
      }
    );
  };

  useEffect(
    () => loadProduct(productId),
    // eslint-disable-next-line
    [productId]
  );

  useEffect(() => {
      catalogService.get(
        "products/noauth/productPriceHistory",
        {
          id: productId, 
          priceType: priceType,
        }, 
        {
          success: (response) => {
            if (response.price != null) {
              setLowestPriceIn30Days(response.price);
            }
          },
          error: () => layout.error('Při načítání historie cen došlo k chybě'),
        }
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId])

  const updatedSimilarProducts = similarProducts
    .filter((p) => p.activeSubstances?.length === product?.activeSubstances?.length)
    .sort(
      (p1, p2) =>
        Math.abs((p1.formId || 0) - (product?.formId || 0)) - Math.abs((p2.formId || 0) - (product?.formId || 0))
    );

  return product === undefined ? (
    <Container maxWidth="lg">Načítání</Container>
  ) : (
    <Container maxWidth="lg">
      {lightBoxOpened && (
        <div className={classes.lightBox}>
          <ReactImageVideoLightbox
            data={product.images?.map((i) => {
              return {
                url: i,
                type: i.includes('youtube') ? 'video' : 'photo',
                altTag: 'desc',
              };
            })}
            startIndex={0}
            showResourceCount={true}
            onCloseCallback={() => setLightBoxOpened(false)}
            style={{ position: 'fixed', zIndex: 999 }}
          />
        </div>
      )}
      <HeadMap currentProduct={product} />
      <br />
      <br />
      <Grid container spacing={3}>
        <Grid xs={12} md={4} item>
          <div onClick={() => setLightBoxOpened(true)} className={`${classes.imageContainer} ${classes.pointer}`}>
            <LabelsContainer product={product} showBottomDescription={true}>
              <div style={{ position: 'relative' }}>
                <img
                  id="previewImage"
                  src={(product.images || [])[0] || '/img/noImage.webp'}
                  alt="Product preview"
                  className={classes.image}
                />
              </div>
            </LabelsContainer>
          </div>
          {(product.images?.length || 0 > 1) && (
            <>
              <br />
              <Grid container spacing={3} onClick={() => setLightBoxOpened(true)} className={classes.pointer}>
                <Grid item xs={3}>
                  {(product.images?.length || 0) > 1 &&
                    ((product.images || [])[1].includes('youtube') ? (
                      <img src="/img/video.webp" alt="Video preview" className={classes.image} />
                    ) : (
                      <img src={(product.images || [])[1]} alt="Product preview" className={classes.image} />
                    ))}
                </Grid>
                <Grid item xs={3}>
                  {(product.images?.length || 0) > 2 &&
                    ((product.images || [])[2].includes('youtube') ? (
                      <img src="/img/video.webp" alt="Video preview" className={classes.image} />
                    ) : (
                      <img src={(product.images || [])[2]} alt="Product preview" className={classes.image} />
                    ))}
                </Grid>
                <Grid item xs={3}>
                  {(product.images?.length || 0) > 3 &&
                    ((product.images || [])[3].includes('youtube') ? (
                      <img src="/img/video.webp" alt="Video preview" className={classes.image} />
                    ) : (
                      <img src={(product.images || [])[3]} alt="Product preview" className={classes.image} />
                    ))}
                </Grid>
                <Grid item xs={3}>
                  {(product.images?.length || 0) > 4 &&
                    ((product.images || [])[4].includes('youtube') ? (
                      <img src="/img/video.webp" alt="Video preview" className={classes.image} />
                    ) : (
                      <img src={(product.images || [])[4]} alt="Product preview" className={classes.image} />
                    ))}
                </Grid>
              </Grid>
            </>
          )}

          <br />
          <div className={classes.substancesButton}>
            <Substancesbutton productId={product.id} showWithText={true} />
          </div>
          <QualityLabels product={product} />
        </Grid>
        <Grid xs={12} md={5} item>
          <EText variant="h4">{product.title}</EText>
          <EText variant="h5">{product.subtitle}</EText>
          <br />
          <EText variant="subtitle1" color="secondary">
            {product.characteristics}
          </EText>
          <br />
          <EText variant="body1">
            <b>Popis produktu</b>
          </EText>
          <EText variant="body1" multiline className={classes.multilineText}>
            {product.shortDescription}
          </EText>
          <br />
          <span onClick={() => scrollToRefObject(detailsRef)}>
            <EText variant="body1" className={classes.pointer}>
              <u>Více informací</u> <FontAwesomeIcon color={theme.palette.secondary.main} icon={faAngleDown} />
            </EText>
          </span>
          <InStockBanner product={product} />
          <ProductPricePanel
            alternativeForms={alternativeForms}
            alternativeTastes={alternativeTastes}
            changeProduct={loadProduct}
            product={product}
            alternatives={alternatives}
            selectedAmmount={selectedAmmount}
            setSelectedAmmount={setSelectedAmmount}
            mmRecommends={reccomendedAlternative}
            minimalPriceIn30Days={lowestPriceIn30Days}
          />
        </Grid>
        <Grid xs={12} md={3} item>
          <MagistrRecommendsCard
            originalUnitPrice={unitPrice}
            product={reccomendedAlternative}
            advantages={product.reccomendedAlternative?.description}
            isCombination={false}
          />
          {combineWith.length === 1 && reccomendedAlternative === undefined && (
            <MagistrRecommendsCard originalUnitPrice={0} product={combineWith[0]} advantages="" isCombination={true} />
          )}
          {combineWith.length === 0 && reccomendedAlternative === undefined && <MiniBanner />}
        </Grid>
      </Grid>
      <br />
      <br />
      {(combineWith.length > 1 || (combineWith.length === 1 && reccomendedAlternative !== undefined)) && (
        <div>
          <EText variant="h6">Kombinujte s</EText>
          <br />
          <Grid container spacing={3}>
            {combineWith.map((cw) => (
              <Grid item xs={12} md={6}>
                <TopSoldProdCard product={cw} />
              </Grid>
            ))}
          </Grid>
          <br />
          <br />
        </div>
      )}
      <DescriptionPanel reference={detailsRef} similarProductsRef={similarProductsRef} product={product} />
      <br />
      <br />
      <br />
      {updatedSimilarProducts.length > 1 && (
        <div ref={similarProductsRef}>
          <EText align="center" variant="h6">
            Podobné produkty v kategorii
          </EText>
          <br />
          <ProductsPreviewPanel products={updatedSimilarProducts.filter((p) => p.id !== product.id)} />
          <br />
          <br />
          <br />
        </div>
      )}
      <DeliveryLeafletOrderPanel />
      <br />
      <br />
      <br />
    </Container>
  );
}
