import { Theme, createStyles, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ZboziReviewCard from './ZboziReviewCard';
import { ordersService } from '../Utils/ApiService';
import IZboziReview from '../Types/zboziReview';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexRoot: {
      padding: '20px 0',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '10px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    flexItem: {
      flex: '1 1 0px',
    },
  })
);

const  ZboziReviewsBanner = () => {
  const classes = useStyles();
  const [reviews, setReviews] = useState<IZboziReview[]>();

  useEffect (() => {
    ordersService.get(
      "/zbozi/reviews",
      { reviewCount: 4},
      {
        success: (response) => {
          setReviews(response);
        },
        error: () => {
          console.log("Could not load Zbozi reviews.");
        }
      }
    )
  }, [])
  
  return reviews ? (
    <>
      <div className={classes.flexRoot}>
        {reviews.map((review, index) => (
          <div key={index} className={classes.flexItem}>
            <ZboziReviewCard review={review} />
          </div>
        ))}
      </div>
    </>
    )
  : null;
}

export default ZboziReviewsBanner;