import { Theme, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import EText from './EText';
import { intervalToDuration } from 'date-fns';
import IZboziReview from '../Types/zboziReview';

interface IProps {
  review: IZboziReview;
}

const getShortenedReview = (reviewTxt: string) => {
  const MAX_CHARS = 100;
  if (reviewTxt.length > MAX_CHARS) {
    return reviewTxt.slice(0, MAX_CHARS) + ' ...';
  }
  return reviewTxt;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      boxSizing: 'border-box',
      gap: '15px',
      boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)',
    },
    icon: {
      height: '32px',
    },

    footerFlex: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
    },
    prosText: {
      color: 'green',
    },
  })
);

const howManyDaysAgo = (timestamp: string) => {
  const reviewDate = new Date(Number(timestamp) * 1000);
  const daysAgo = intervalToDuration({
    start: reviewDate,
    end: new Date(),
  }).days;
  if (daysAgo === undefined) {
    return '';
  } else if (daysAgo === 0) {
    return 'dnes';
  } else if (daysAgo === 1) {
    return 'včera';
  } else {
    return `před ${daysAgo} dny`;
  }
};

const ReviewText = ({ text }: { text: string }) => {
  const textWithCapitalizedFirstChar = text.charAt(0).toUpperCase() + text.slice(1);
  return (
    <>
      <EText variant="body1">
        <i>"{getShortenedReview(textWithCapitalizedFirstChar)}"</i>
      </EText>
    </>
  );
}

const ZboziReviewCard = ({ review }: IProps) => {
  const classes = useStyles();
  const daysAgoString = howManyDaysAgo(review.timestamp);
  return (
    <>
      <div className={classes.card}>
        <div>
          <EText variant="body1">
            <b>Recenze zákazíka</b>
          </EText>
          <ReviewText text={review.text} />
        </div>

        <div className={classes.footerFlex}>
          <img className={classes.icon} src="/img/zbozi.svg" alt="logo zbozi" />
          <div>
            <EText variant="body2" style={{ color: '#696969' }}>
              Ověřený zákazník, {daysAgoString}
            </EText>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default ZboziReviewCard;
