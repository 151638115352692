import React from 'react';
import { createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import EText from './EText';
import ESelect from './ESelect';
import CartButton from './CartButton';
import { round, separateThousands } from '../Utils/Common';
import ECounter from './ECounter';
import { getPrice } from '../Utils/Product';
import { useLayout } from '../Layout/LayoutContext';
import { InfoOutlined } from '@material-ui/icons';
import Environment from '../Environments';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#F6F8FB',
      borderRadius: 4,
      padding: 16,
    },
    flex: {
      display: 'flex',
    },
    pricePerUnitText: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: 8,
      marginRight: 8,
    },
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    cartButton: {
      marginLeft: 12,
    },
    sizesSelect: {
      width: 132,
      margin: 12,
    },
    strikethrough: {
      textDecoration: 'line-through',
    },
  })
);

interface IProps {
  product: IProduct;
  alternatives: IProduct[];
  alternativeForms: IProduct[];
  alternativeTastes: IProduct[];
  changeProduct: (id: number) => void;
  selectedAmmount: number;
  setSelectedAmmount: (val: number) => void;
  mmRecommends?: IProduct;
  minimalPriceIn30Days: number;
}

export default function ProductsPricePanel({
  product,
  alternatives,
  changeProduct,
  selectedAmmount,
  setSelectedAmmount,
  alternativeForms,
  alternativeTastes,
  mmRecommends,
  minimalPriceIn30Days,
}: IProps) {
  const classes = useStyles();

  const layout = useLayout();
  const price = getPrice(product, layout.homeConfig.todaysSpecial);
  const unitPrice = price / (product.ammount || 1);
  const allSizes = [...alternatives, product]
    .filter((p) => p.ammount !== undefined)
    .map((p) => {
      return {
        ammount: p.ammount || 0,
        price: getPrice(p, layout.homeConfig.todaysSpecial) / (p.ammount || 1),
        totalPrice: getPrice(p, layout.homeConfig.todaysSpecial),
        recommendedPrice: p.reccomendedPrice,
        id: p.id,
        unitText: p.unitText,
        unit: p.unit,
      };
    })
    .sort((a, b) => a.ammount - b.ammount);
  
  return (
    <div className={classes.root}>
      <span className={classes.flex}>
        <EText variant="h4">
          <b>{separateThousands(price)} Kč</b>
        </EText>
        {price < (product.reccomendedPrice || 0) && (
          <>
            &nbsp;&nbsp;&nbsp;
            <EText variant="h6" color="secondary">
              <b className={classes.strikethrough}>{separateThousands(product.reccomendedPrice)} Kč</b>
            </EText>
            &nbsp;
            {(Environment.domain !== 'magistrmartin.cz') && (
              <Tooltip title="Orientační sleva oproti doporučené prodejní ceně v lékárně." placement="bottom">
                <InfoOutlined fontSize="small" />
              </Tooltip>
            )}
          </>
        )}
        <EText variant="caption" className={classes.pricePerUnitText}>
          {separateThousands(round(unitPrice, 2))} Kč/{product.unit}
        </EText>
      </span>
      
      <EText style={{color: "grey", fontSize: "0.8rem"}}>Minimální prodejní cena za posledních 30 dní:&nbsp; 
        {price === minimalPriceIn30Days 
        ? separateThousands(minimalPriceIn30Days) + " Kč"
        : separateThousands(minimalPriceIn30Days) + " Kč - sleva " + (100 - Math.ceil(price * 100 / minimalPriceIn30Days)) + " %"
        }
        
      </EText>
    
      {allSizes.length > 1 && (
        <>
          <br />
          {allSizes.map((act) => (
            <EText key={`${product.id}${act.ammount}`} bold={act.ammount === product.ammount}>
              {act.ammount} {product.unitText} - {separateThousands(getPrice(act, layout.homeConfig.todaysSpecial))} Kč{' '}
              {act.totalPrice < (act.recommendedPrice || 0) && (
                <span className={classes.strikethrough}>{separateThousands(act.recommendedPrice)} Kč</span>
              )}{' '}
              - {round(act.price, 2)} Kč/{product.unit}
              {act.price < unitPrice
                ? ` (Výhodnější o ${separateThousands(Math.round(100 * (1 - act.price / unitPrice)))} %)`
                : act.id === product.id
                  ? ' (Aktuální balení)'
                  : act.ammount < (product.ammount || 0)
                    ? ' (Menší balení)'
                    : ''}
            </EText>
          ))}
          <span className={classes.flex}>
            <EText variant="caption" className={classes.verticalCenter}>
              Velikost balení:
            </EText>
            <ESelect
              onChange={changeProduct}
              value={product.id}
              options={[...allSizes].map((as) => {
                return {
                  key: as.id,
                  value: `${as.ammount} ${product.unitText}`,
                };
              })}
              className={classes.sizesSelect}
            />
          </span>
        </>
      )}
      <br />
      <span className={classes.flex}>
        <ECounter value={selectedAmmount} onChange={setSelectedAmmount} />
        <EText inline className={classes.pricePerUnitText}>
          ks
        </EText>
        <CartButton
          className={classes.cartButton}
          product={product}
          ammount={selectedAmmount}
          alternative={mmRecommends}
        />
      </span>
      {alternativeForms.length > 0 && (
        <>
          <br />
          <EText variant="caption">Alternativní lékové formy</EText>
          <br />
          <ESelect
            onChange={changeProduct}
            value={-1}
            fullWidth={true}
            options={[
              { key: -1, value: 'Vyberte alternativní produkt' },
              ...alternativeForms.map((af) => {
                return {
                  key: af.id,
                  value: `${af.form} - ${af.title} ${af.subtitle} - ${separateThousands(
                    getPrice(af, layout.homeConfig.todaysSpecial)
                  )} Kč`,
                };
              }),
            ]}
          />
        </>
      )}
      {alternativeTastes.length > 0 && (
        <>
          <br />
          <EText variant="caption">Alternativní příchutě</EText>
          <br />
          <ESelect
            onChange={changeProduct}
            value={-1}
            fullWidth={true}
            options={[
              { key: -1, value: 'Vyberte alternativní produkt' },
              ...alternativeTastes.map((af) => {
                return {
                  key: af.id,
                  value: `${af.form} - ${af.title} ${af.subtitle} - ${separateThousands(
                    getPrice(af, layout.homeConfig.todaysSpecial)
                  )} Kč`,
                };
              }),
            ]}
          />
        </>
      )}
      {price < (product.reccomendedPrice || 0) && (
        <>
          <br />
          <EText variant="caption">Akční cena platí do vyprodání zásob.</EText>
        </>
      )}
    </div>
  );
}
