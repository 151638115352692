import React from 'react';
import { createStyles, Grid, makeStyles, Typography, Theme } from '@material-ui/core';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import EText from './EText';
import { getPrice } from '../Utils/Product';
import { cutString, round, separateThousands } from '../Utils/Common';
import CartButton from './CartButton';
import { useHistory } from 'react-router';
import LabelsContainer from './LabelsContainer';
import { useLayout } from '../Layout/LayoutContext';
import Environment from '../Environments';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 4,
      height: '100%',
      maxWidth: '50%',
      border: `1px solid ${theme.palette.primary.main}`,
      position: 'relative',
      cursor: 'pointer',
    },
    head: {
      paddingTop: 12,
      paddingBottom: 8,
      backgroundColor: theme.palette.primary.main,
    },
    body: {
      padding: 16,
    },
    image: {
      width: '100%',
      borderRadius: 6,
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    flexGrow: {
      flexGrow: 1,
    },
  })
);

interface IProps {
  product?: IProduct;
}

export default function TopProductCard({ product }: IProps) {
  const classes = useStyles();
  const history = useHistory();
  const layout = useLayout();

  return (
    (product && product.published && (
      <div
        className={classes.root}
        onClick={() =>
          history.push(
            `/product/${product.title?.replace(/%/g, '')?.replace(/[ ]/g, '-')}-${product.subtitle
              ?.replace(/%/g, '')
              ?.replace(/[ ]/g, '-')}-${product.id}`
          )
        }
      >
        <div className={classes.head}>
          <EText variant="h6" color="contrast" align="center">
            {Environment.fullName}
          </EText>
          <EText color="contrast" align="center">
            <b>doporučuje</b>
          </EText>
        </div>
        <div className={classes.body}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <EText variant="h6">{product.title}</EText>
              <EText>
                <b>{product.subtitle}</b>
              </EText>
              <br />
              <br />
              <LabelsContainer product={product} showBottomDescription={false}>
                <img
                  src={(product.images?.length || 0) > 0 ? (product.images || [])[0] : '/img/noImage.webp'}
                  alt="product preview"
                  className={classes.image}
                />
              </LabelsContainer>
            </Grid>
            <Grid item xs={12} sm={6}>
              <EText variant="body1" multiline align="justify">
                {cutString(product.shortDescription || '', 182)}
              </EText>
              <hr />
              <EText variant="body1">{product.characteristics}</EText>
              <hr />
              <div className={classes.flex}>
                <div>
                  <EText variant="h4">{separateThousands(getPrice(product, layout.homeConfig.todaysSpecial))} Kč</EText>
                  <EText>
                    {separateThousands(
                      round(getPrice(product, layout.homeConfig.todaysSpecial) / (product.ammount || 1), 2)
                    )}{' '}
                    Kč/{product.unit}
                  </EText>
                </div>

                {
                  //@ts-ignore
                  getPrice(product) < (product.reccomendedPrice || 0) && (
                    <>
                      <div className={classes.flexGrow}>&nbsp;</div>
                      <Typography
                        noWrap
                        display="inline"
                        style={{ textDecoration: 'line-through' }}
                        align="center"
                        color="secondary"
                        variant="h5"
                      >
                        {separateThousands((product as any).reccomendedPrice)} Kč
                      </Typography>
                    </>
                  )
                }
              </div>
              <hr />
              <br />
              <CartButton product={product} ammount={1} />
            </Grid>
          </Grid>
        </div>
      </div>
    )) || <></>
  );
}
