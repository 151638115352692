import React from 'react';
import { createStyles, makeStyles, Theme, Typography, Tooltip } from '@material-ui/core';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import EText from './EText';
import { useHistory } from 'react-router-dom';
import CartButton from './CartButton';
import { cutString, round, separateThousands } from '../Utils/Common';
import LabelsContainer from './LabelsContainer';
import { getPrice, showCzechIcon } from '../Utils/Product';
import { useLayout } from '../Layout/LayoutContext';
import Environment from '../Environments';
import { PRODUCT_PREVIEW_BORDER_RADIUS } from '../Utils/Constants';
import Substancesbutton from './SubstancesButton';
import { usePickupPlaces } from '../Hooks/PickupPlaces';
import CzechProductIcon from './CzechProductIcon';
import { InfoOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '90%',
      borderRadius: PRODUCT_PREVIEW_BORDER_RADIUS,
      marginBottom: 16,
      border: '2px solid #ebedf0',
      cursor: 'pointer',
      position: 'relative',
      overflow: 'hidden',
    },
    image: {
      width: '200px',
      height: '250px',
      aspectRatio: '4/5',
      objectFit: 'contain',
    },
    cartButton: {},
    availableText: {
      fontWeight: 'bold',
      color: 'green',
    },
    unavailableText: {
      fontWeight: 'bold',
      color: 'red',
    },
    leafWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 8,
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 16,
      width: '90%',
    },
    leafButton: {
      borderRadius: '50%',
      backgroundColor: '#84B517',
      color: '#FFFFFF',
      padding: 8,
      cursor: 'pointer',
    },
    price: {},
    labelContainer: {
      fontFamily: 'Roboto',
      color: 'white',
      padding: 4,
      margin: 0,
      textAlign: 'center',
      '&:hover': {
        opacity: '90%',
      },
    },
    description: {
      paddingLeft: 10,
      paddingRight: 10,
      marginTop: 12,
      marginBottom: 24,
      textAlign: 'justify',
      textAlignLast: 'left',
      fontSize: '0.85rem',
      hyphens: 'auto',
      minHeight: '62px',
    },
    unitPrice: {
      fontSize: '0.75rem',
      fontFamily: 'Roboto',
      color: 'grey',
      width: '100%',
      textAlign: 'center',
      marginBottom: 8,
    },
    availability: {
      marginTop: 8,
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
    },
    caption: {
      marginTop: 6,
      fontSize: '1.25rem',
      lineHeight: '1.1em',
      letterSpacing: 1,
    },
    wrapper: {
      minHeight: '95px',
    },
    horizontalStack: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
    },
  })
);

interface IPorps {
  product: IProduct;
}

export default function ProductsPreviewCard({ product }: IPorps) {
  const classes = useStyles();
  const layout = useLayout();
  const price = getPrice(product, layout.homeConfig.todaysSpecial);
  const history = useHistory();
  const { data: pickupPlaces = [] } = usePickupPlaces();

  const availablePlaces = pickupPlaces.filter(
    (p) => (product?.stockState?.find((ss) => ss.placeId === p.id)?.ammount || 0) > 0
  );
  return (
    <div
      className={classes.paper}
      onClick={() =>
        history.push(
          `/product/${product.title?.replace(/%/g, '')?.replace(/\//g, '')?.replace(/[ ]/g, '-')}-${product.subtitle
            ?.replace(/%/g, '')
            ?.replace(/\//g, '')
            ?.replace(/[ ]/g, '-')}-${product.id}`
        )
      }
    >
      <LabelsContainer product={product} showBottomDescription={false}>
        <div className={classes.item}>
          <img
            key={`img-product-preview-${product.id}`}
            src={`${
              (product.images?.length || 0) > 0 ? (product.images as string[])[0] : '/img/noImage.webp'
            }?size=512`}
            alt="product preview"
            className={classes.image}
            loading="lazy"
            width={200}
            height={250}
            
          />

          <div className={classes.wrapper}>
            <EText align="center" color="secondary" variant="h6" className={classes.caption}>
              {product.title}
            </EText>
            <EText align="center" color="primary" variant="subtitle2">
              {product.subtitle}
            </EText>
          </div>

          <EText className={classes.description} color="primary" variant="body1">
            {(product.title?.length || 0) < 30 ? <></> : <></>}
            {product?.shortDescription?.includes('\r\n')
              ? cutString(product?.shortDescription || '', 100).replace(/\r\n/g, '. ')
              : cutString(product?.shortDescription || '', 100).replace(/\n/g, '. ')}
          </EText>
          <EText className={classes.price} inline align="center" color="primary" variant="h4">
            {separateThousands(price)} Kč{' '}
            {(price || 0) < (product.reccomendedPrice || 0) && (
              <span>
                <Typography
                  noWrap
                  display="inline"
                  style={{ textDecoration: 'line-through' }}
                  align="center"
                  color="secondary"
                  variant="h6"
                >
                  {separateThousands(product.reccomendedPrice)} Kč
                </Typography>
                &nbsp;
                {(Environment.domain !== 'magistrmartin.cz') && (
                  <Tooltip title="Orientační sleva oproti doporučené prodejní ceně v lékárně." placement="top">
                    <InfoOutlined fontSize="small" />
                  </Tooltip>
                )}
              </span>
            )}
          </EText>
          <span className={classes.unitPrice}>
            {separateThousands(round((price || 0) / (product.ammount || 1), 2))} Kč/{product.unit}
          </span>
          <CartButton className={classes.cartButton} product={product} onClick={(e) => e.stopPropagation()} />
          <div className={classes.availability}>
            {
              product.unavailable 
              ? 
                <EText className={classes.unavailableText} style={{ color: 'red' }}>
                  Dočasně nedostupné na e-shopu
                </EText>
              : 
                (
                  (product.stockAmmount || 0) > 0 || 
                  ((product.expiration || '') !== '' && new Date(product.expiration || '').getFullYear() > 2000) || 
                  (product.stockState && product.stockState.some(item => item.ammount > 0)) ||
                  (product.pharmosStockState && product.pharmosStockState > 0)
                ) ?
                  <EText className={classes.availableText} style={{ color: 'green' }}>
                    Skladem na e-shopu
                  </EText>
                  :
                  <EText className={classes.unavailableText} style={{ color: 'red' }}>
                    Dočasně nedostupné na e-shopu
                  </EText>
            }  
            {Environment.showReservation && (
              <>
                {product.pharmasStock && availablePlaces.length > 0 ? (
                  <EText style={{ fontSize: 12, color: 'green' }}>
                    K rezervaci na {availablePlaces.length} {availablePlaces.length > 1 ? 'lékárnách' : 'lékárně'}
                  </EText>
                ) : (
                  <EText style={{ fontSize: 12, color: 'green' }}>&nbsp;</EText>
                )}
              </>
            )}
          </div>
          <div className={classes.leafWrapper}>
            <EText color="primary" variant="caption">
              {product.characteristics}
            </EText>
            <div className={classes.horizontalStack}>
              {showCzechIcon(product) && <CzechProductIcon />}
              <Substancesbutton productId={product.id} />
            </div>
          </div>
        </div>
      </LabelsContainer>
    </div>
  );
}
