import React, { useEffect, useState } from 'react';
import { Container, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { webStructureService } from '../Utils/ApiService';
import EText from '../Components/EText';
import Environment from '../Environments';
import HeurekaInlineBadge, { SHOW_HEUREKA_WIDGETS } from '../Components/HeurekaInlineBadge';
import FooterLinks, { ILinksCategory } from '../Components/FooterLinks';
import HeurekaReviewsBanner from '../Components/HeurekaReviewsBanner';
import ZboziReviewsBanner  from '../Components/ZboziReviewsBanner';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100vw',
      backgroundColor: '#F6F8FB',
      paddingTop: '96px',
    },
    logo: {
      width: 224,
    },

    suklImg: {
      width: '80%',
      marginTop: '64px',
      cursor: 'pointer',
      marginBottom: '32px',
    },
    deliveryFooter: {
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      justifyContent: 'space-between',
      marginBlockStart: '16px',
      marginBlockEnd: '16px',
      paddingInlineEnd: '64px',
    },
    companyText: {
      marginTop: 'auto',
      marginBlockEnd: '16px',
    },
    leftColWrapper: {
      flexDirection: 'column',
      display: 'flex',
    },
    flexGrow: {
      flexGrow: 1,
    },
    suklLogo: {
      width: 180,
    },
  })
);

interface IProps {
  suklVerifyLogoVisible: boolean;
  veterinaryVerifyLogoVisible: boolean;
  href: string;
  hideFooter: boolean;
}

export default function Footer({ suklVerifyLogoVisible, veterinaryVerifyLogoVisible, href, hideFooter }: IProps) {
  const classes = useStyles();
  const [links, setLinks] = useState<ILinksCategory[]>([]);

  useEffect(() => {
    webStructureService.get(
      '/footer/',
      { domain: Environment.domain },
      {
        success: setLinks,
        error: console.log,
      }
    );
  }, []);

  return (
    (!hideFooter && (
      <>
        {SHOW_HEUREKA_WIDGETS && (
          <Container maxWidth="lg">
            <HeurekaReviewsBanner />
          </Container>
        )}

        {process.env.REACT_APP_ESHOP_VARIANT === 'patrik' && (
        <Container maxWidth="lg">
          <ZboziReviewsBanner />
        </Container>
        )}

        <div className={classes.root}>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={12} md={5} className={classes.leftColWrapper}>
                <img src={Environment.logoPath} alt="magistr martin logo" className={classes.logo} />
                <br />
                <iframe
                  src={Environment.facebookLink}
                  width="340"
                  height="160"
                  style={{ border: 'none', overflow: 'hidden' }}
                  scrolling="no"
                  frameBorder="0"
                  allowFullScreen
                  title="facebook page widget"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                ></iframe>
                <br />
                <HeurekaInlineBadge />
                <br />

                <EText variant="body1" className={classes.companyText}>
                  © Magistr Martin IT team
                </EText>
              </Grid>
              <Grid item xs={12} md={7} id="asdf">
                <Grid container>
                  <FooterLinks links={links} />

                  {!Environment.hideSUKLLogo && (
                    <>
                      {suklVerifyLogoVisible && (
                        <Grid item xs={12} md={4}>
                          {/* leave this eslint disabled - we don't want to set rel norefferer, because visited site need to know from where request came from */}
                          {/* eslint-disable-next-line */}
                          <a
                            href="https://prehledy.sukl.cz/prehledy.html#/lekarny/00203413390?verify=true"
                            target="_blank"
                          >
                            <img
                              src="/img/LogoSancoInternet_CS_B-300.webp"
                              title="Logo pro zásilkový výdej léčivých přípravků"
                              alt="Logo pro zásilkový výdej léčivých přípravků"
                              className={classes.suklLogo}
                            />
                          </a>
                          <br />
                          <br />
                        </Grid>
                      )}
                      {veterinaryVerifyLogoVisible && (
                        <Grid item xs={12} md={4}>
                          {/* leave this eslint disabled - we don't want to set rel norefferer, because visited site need to know from where request came from */}
                          {/* eslint-disable-next-line */}
                          <a href="https://www.uskvbl.cz/cs/lekarny/00203413390" target="_blank">
                            <img
                              src="/img/LogoVET-CS_B_RGB-300.webp"
                              title="Logo pro zásilkový výdej veterinárních léčivých přípravků"
                              alt="Logo pro zásilkový výdej veterinárních léčivých přípravků"
                              className={classes.suklLogo}
                            />
                          </a>
                          <br />
                          <br />
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item xs={12} md={12} className={classes.deliveryFooter}>
                  <img
                    src="https://api.magistrmartin.cz/images/noauth/image/images/Red Zasilkovna.webp"
                    alt="Zásilkovna logo"
                    width={128}
                    height={32}
                    style={{ alignSelf: 'center' }}
                  />
                  <img
                    src="https://api.magistrmartin.cz/images/noauth/image/images/1692781874505logo-h.webp"
                    alt="Colgate logo"
                    width={98}
                    height={24}
                    style={{ alignSelf: 'center' }}
                  />
                  <img
                    src="https://api.magistrmartin.cz/images/noauth/image/images/1692781905269Visa_Brandmark_Blue_RGB_2021.webp"
                    alt="VISA logo"
                    width={53}
                    height={18}
                    style={{ alignSelf: 'center' }}
                  />
                  <img
                    src="https://api.magistrmartin.cz/images/noauth/image/images/1692781918566mc_symbol_opt_73_3x.webp"
                    alt="MasterCard logo"
                    width={44}
                    height={32}
                    style={{ alignSelf: 'center' }}
                  />
                  <img
                    src="https://api.magistrmartin.cz/images/noauth/image/images/1692781945456GPay_Acceptance_Mark_800(1).webp"
                    alt="Google Pay logo"
                    width={45}
                    height={24}
                    style={{ alignSelf: 'center' }}
                  />
                  <img
                    src="https://api.magistrmartin.cz/images/noauth/image/images/1692781930683logoapplepay(1)(1).webp"
                    alt="Apple Pay logo"
                    width={45}
                    height={32}
                    style={{ alignSelf: 'center' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
    )) || <br />
  );
}
