import React from 'react';
import { createStyles, makeStyles, Theme, Typography, Hidden, Box } from '@material-ui/core';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import EText from './EText';
import { getPrice } from '../Utils/Product';
import CartButton from './CartButton';
import { useHistory } from 'react-router';
import { Skeleton } from '@material-ui/lab';
import { round, separateThousands } from '../Utils/Common';
import { useLayout } from '../Layout/LayoutContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      gap: '15px',
      alignItems: 'end',
      borderRadius: 4,
      border: `1px solid ${theme.palette.primary.main}`,
      position: 'relative',
      marginTop: 16,
      padding: 8,
      cursor: 'pointer',
      height: '33%',
      width: '100%',
    },
    image: {
      width: 100,
    },
    flexGrow: {
      flexGrow: 1,
    },
    imageSkeleton: {
      width: 100,
    },
    skeletonDescContainer: {
      width: 'calc(60% - 20px)',
    },
    titleSkeleton: {
      width: '96%',
    },
    subtitleSkeleton: {
      width: '60%',
    },
    descSkeleton: {
      width: '96%',
    },
    verticalFlex: {
      height: '100%',
      width: 'fit-content',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'end',
    },
  })
);

interface IProps {
  product: IProduct | undefined;
}

export default function TopSoldProdCard({ product }: IProps) {
  const classes = useStyles();
  const history = useHistory();
  const layout = useLayout();

  return (
    (product && (
      <div
        className={classes.root}
        onClick={() =>
          history.push(
            `/product/${product.title?.replace(/%/g, '')?.replace(/[ ]/g, '-')}-${product.subtitle?.replace(
              /%/g,
              ''
            )}-${product.id}`
          )
        }
      >
        <Hidden xsDown>
          <img
            key={`img-topsold-${product.id}`}
            src={`${
              (product.images?.length || 0) > 0 ? (product.images as string[])[0] : '/img/noImage.webp'
            }?size=512`}
            alt="product preview"
            className={classes.image}
          />
        </Hidden>
        <div style={{ width: '100%' }}>
          <EText color="secondary" variant="h6">
            {product.title}
          </EText>
          <EText color="primary" variant="body1">
            {product.subtitle}
          </EText>
          <br />
          <EText color="primary" variant="body1">
            {product.characteristics}
          </EText>
        </div>
        <div className={classes.verticalFlex}>
          <Box>
            {(getPrice(product, layout.homeConfig.todaysSpecial) || 0) < (product.reccomendedPrice || 0) && (
              <Typography
                noWrap
                display="inline"
                style={{ textDecoration: 'line-through' }}
                align="center"
                color="secondary"
                variant="body1"
              >
                {separateThousands(round(product.reccomendedPrice || 0, 2))} Kč
              </Typography>
            )}
          </Box>

          <Box>
            <EText variant="h5" color="primary">
              {separateThousands(round(getPrice(product, layout.homeConfig.todaysSpecial), 2))} Kč
            </EText>
          </Box>
          <Box
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            <CartButton product={product} ammount={1} />
          </Box>
        </div>
      </div>
    )) || (
      <div className={classes.root}>
        <div className={classes.verticalFlex}>
          <Skeleton className={classes.imageSkeleton} animation="wave" />
          <div className={classes.skeletonDescContainer}>
            <EText color="secondary" variant="h6">
              <Skeleton className={classes.titleSkeleton} animation="wave" />
            </EText>
            <EText color="primary" variant="body1">
              <Skeleton className={classes.subtitleSkeleton} animation="wave" />
            </EText>
            <br />
            <EText color="primary" variant="body1">
              <Skeleton className={classes.descSkeleton} animation="wave" />
            </EText>
          </div>
        </div>
      </div>
    )
  );
}
